<template>
  <v-autocomplete
    label="Factura"
    placeholder="Seleccione una factura"
    :loading="loading"
    :items="items"
    item-text="invoice_number"
    item-value="id"
    return-object
    v-bind:value="value"
    v-on:input="$emit('input', $event)"
    :search-input.sync="search"
    ref="auto"
  ></v-autocomplete>
</template>

<script>
import requests from "@/services/requests";
import rules from "@/utils/form.rules";
export default {
  props: {
    reset: { type: Boolean, required: false },
    value: [Object],
    reload: [Boolean],
    providerId: [Number],
    businessId: [Number],
    type: [String],
    exclude: [Array],
  },
  data: () => ({
    loading: false,
    items: [],
    onlyRequired: [rules.required],
    search: "",
  }),
  methods: {
    getInvoices(param = "") {
      this.loading = true;
      let uri = "/api/invoice/?payable=true&company_id=" + this.businessId;
      if (this.providerId > 0) {
        uri += "&provider_id=" + this.providerId;
      }
      if (this.type == "VOUCHER,INVOICE") {
        uri += "&invoice_type__in=" + this.type;
      } else {
        uri += "&invoice_type=" + this.type;
      }
      if (this.search) {
        uri += "&search=" + param;
      }
      requests.get(uri).then((res) => {
        if (res.status == 200) {
          this.items = res.data;
          this.excludeSameInvoices(this.items);
          this.loading = false;
        } else {
          console.log(res);
        }
      });
    },
    excludeSameInvoices() {
      let index;
      if (this.exclude.length > 0) {
        this.exclude.forEach((el) => {
          index = this.items.findIndex((d) => d.id == el);
          this.items.splice(index, 1);
        });
      }
    },
  },
  watch: {
    reset: function () {
      this.$refs.auto.reset();
    },
    reload: function () {
      this.getInvoices();
    },
    providerId: function () {
      this.getInvoices();
    },
    type: function () {
      this.getInvoices();
    },
    businessId: function () {
      this.getInvoices();
    },
    exclude: function () {
      this.getInvoices();
    },
    search: function () {
      this.getInvoices(this.search);
    },
  },
  mounted() {
    this.getInvoices();
  },
};
</script>
